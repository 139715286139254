import "./App.scss";
import BssLogo from "./BssLogo";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <BssLogo />
        <p>
          <code>Bollman Software Solutions</code>
        </p>
      </header>
    </div>
  );
}

export default App;

